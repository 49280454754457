<template>
  <div>
    <v-text-field
      v-if="text"
      v-model="inputVal"
      v-on="$listeners"
      :label="label"
      :class="`${uppercase && 'text-uppercase'} ${classname}`"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :type="type"
      :readonly="readonly"
      :value="value"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :append-icon="append"
      :prefix="prefix"
      :hint="hint"
      hide-details="auto"
      dense
      :rules="rules"
      :required="required"
      clear-icon="mdi-close-thick"
      autocomplete="off"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
      <template v-if="appendIcon">
        <v-icon slot="append" color="#ee1d23"> mdi-star-circle </v-icon>
      </template>
    </v-text-field>
    <v-currency-field
      v-if="currency"
      v-model="inputVal"
      v-on="$listeners"
      :label="label"
      :class="classname"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :type="type"
      :readonly="readonly"
      :value="value"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :append-icon="append"
      hide-details
      dense
      :rules="rules"
      :required="required"
      clear-icon="mdi-close-thick"
    />
    <v-row v-if="datePicker">
      <v-col cols="2" class="pr-0">
        <v-autocomplete
          autofocus
          :items="range(1, 31)"
          v-model="inputDate[2]"
          class="text-uppercase datepicker"
          :label="label"
          color="primary"
          hide-details="auto"
          append-icon=""
          dense
          @input="$emit('input', inputDate.join('-'))"
          @focus="$emit('input', inputDate.join('-'))"
          :menu-props="{ auto: true, maxHeight: '50px' }"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="7" class="px-0">
        <v-autocomplete
          :items="[
            { text: 'Januari', value: '01' },
            { text: 'Februari', value: '02' },
            { text: 'Maret', value: '03' },
            { text: 'April', value: '04' },
            { text: 'Mei', value: '05' },
            { text: 'Juni', value: '06' },
            { text: 'Juli', value: '07' },
            { text: 'Agustus', value: '08' },
            { text: 'September', value: '09' },
            { text: 'Oktober', value: '10' },
            { text: 'November', value: '11' },
            { text: 'Desember', value: '12' },
          ]"
          v-model="inputDate[1]"
          class="text-uppercase text-center"
          color="primary"
          :filter="
            (item, queryText, itemText) => {
              const textOne = item.text.toLowerCase();
              const textTwo = item.value.toLowerCase();
              const searchText = queryText.toLowerCase();

              return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
              );
            }
          "
          hide-details="auto"
          append-icon=""
          dense
          @input="$emit('input', inputDate.join('-'))"
          @focus="$emit('input', inputDate.join('-'))"
          :menu-props="{ auto: true, maxHeight: '50px' }"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="3" class="pl-0">
        <v-autocomplete
          :items="range(1900, new Date().getFullYear() + 1, true)"
          v-model="inputDate[0]"
          class="text-uppercase"
          color="primary"
          hide-details="auto"
          append-icon=""
          dense
          @input="$emit('input', inputDate.join('-'))"
          @focus="$emit('input', inputDate.join('-'))"
          :menu-props="{ auto: true, maxHeight: '50px' }"
        >
          <template slot="append">
            <v-icon class="pt-1" small @click="$emit('clear')">mdi-close-thick</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-textarea
      v-if="textarea"
      v-model="inputVal"
      v-on="$listeners"
      :label="label"
      :class="`${uppercase ? 'text-uppercase' : ''} ${classname}`"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :type="type"
      :rows="rows"
      :placeholder="placeholder"
      :autofocus="autofocus"
      hide-details="auto"
      auto-grow
      dense
      row-height="18px"
      clear-icon="mdi-close-thick"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-textarea>
    <v-select
      v-if="select"
      :items="itemSelect"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="inputVal"
      :label="label"
      :class="`${uppercase ? 'text-uppercase' : ''} ${classname}`"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :multiple="multiple"
      :small-chips="chips"
      :deletable-chips="chips"
      :autofocus="autofocus"
      clear-icon="mdi-close-thick"
      hide-details="auto"
      dense
      :solo="solo"
      :flat="flat"
      :placeholder="placeholder"
      :item-color="background"
      :append-icon="append"
      v-on="$listeners"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
      <template v-if="tipe" v-slot:item="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'etiket_putih' || item.value == 'udd' || item.value == 'racikan' ? 'grey lighten-2' : 'blue'
          } mr-1`"
          left
          tile
          size="16"
        ></v-avatar>
        <div class="text-uppercase v-list-item__title" v-text="item.text" />
      </template>
      <template v-else-if="etiket" v-slot:item="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'ip'
              ? 'yellow'
              : item.value == 'udd'
              ? 'pink lighten-1'
              : item.value == 'injeksi'
              ? 'light-green'
              : item.value == 'cairan_infus'
              ? 'purple'
              : 'orange'
          } mr-1`"
          left
          tile
          size="16"
        ></v-avatar>
        <div class="text-uppercase v-list-item__title" v-text="item.text" />
      </template>
      <template v-else-if="triase" v-slot:item="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'darurat'
              ? 'red'
              : item.value == 'gawat'
              ? 'yellow'
              : 'green'
          } mr-1`"
          left
          tile
          size="16"
        ></v-avatar>
        <div class="text-uppercase v-list-item__title" v-text="item.text" />
      </template>
      <template v-else-if="prioritas" v-slot:item="{ item, index }">
        <v-avatar :class="`${item.color} mr-1`" left tile size="16"></v-avatar>
        <div class="text-uppercase v-list-item__title" v-text="item.text" />
      </template>
      <template v-if="tipe" v-slot:selection="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'etiket_putih' || item.value == 'udd' || item.value == 'racikan' ? 'grey lighten-2' : 'blue'
          } mr-1`"
          left
          tile
          size="14"
        ></v-avatar>
        <div
          class="v-select__selection v-select__selection--comma"
          v-text="item.text"
        />
      </template>
      <template v-else-if="etiket" v-slot:selection="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'ip'
              ? 'yellow'
              : item.value == 'udd'
              ? 'pink lighten-1'
              : item.value == 'injeksi'
              ? 'light-green'
              : item.value == 'cairan_infus'
              ? 'purple'
              : 'orange'
          } mr-2`"
          left
          tile
          size="14"
        ></v-avatar>
        <div
          class="v-select__selection v-select__selection--comma"
          v-text="item.text"
        />
      </template>
      <template v-else-if="triase" v-slot:selection="{ item, index }">
        <v-avatar
          :class="`${
            item.value == 'darurat'
              ? 'red'
              : item.value == 'gawat'
              ? 'yellow'
              : 'green'
          } mr-1`"
          left
          tile
          size="14"
        ></v-avatar>
        <div
          class="v-select__selection v-select__selection--comma"
          v-text="item.text"
        />
      </template>
      <template v-else-if="prioritas" v-slot:selection="{ item, index }">
        <v-avatar :class="`${item.color} mr-2`" left tile size="14"></v-avatar>
        <div
          class="v-select__selection v-select__selection--comma"
          v-text="item.text"
        />
      </template>
      <template v-else-if="obat" v-slot:selection="{ item, index }">
        <v-chip close @click:close="inputVal.splice(index, 1)">
          <span>{{ item.obat }}</span>
        </v-chip>
      </template>
    </v-select>
    <v-combobox
      v-if="autocomplete"
      :items="item"
      v-model="inputVal"
      :label="label"
      :class="`${uppercase ? 'text-uppercase' : ''} ${classname}`"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :multiple="multiple"
      :small-chips="chips"
      :deletable-chips="chips"
      :loading="isLoading"
      :autofocus="autofocus"
      clear-icon="mdi-close-thick"
      hide-details="auto"
      dense
      :solo="solo"
      :flat="flat"
      :placeholder="placeholder"
      :background-color="background"
      :append-icon="append"
      :prepend-inner-icon="prepend"
      :search-input.sync="search"
      @change="search = null"
      no-filter
      :menu-props="{ maxHeight: 150 }"
      v-on="$listeners"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
      <template v-if="multiple" v-slot:selection="data">
        <v-chip
          class="mx-0 mr-2 pl-0 pr-1"
          style="border: none; height: 25px; font-size: inherit"
          outlined
          label
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          :ripple="false"
          @click="data.select"
          @click:close="remove(inputVal, data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
      <template v-slot:item="{ item, i }">
        <div class="text-uppercase py-1 v-list-item__title" v-text="item" />
        <v-btn icon x-small
        @click.stop="deleteItem(item)">
          <v-icon
            x-small
            title="Hapus Data"
          >
            mdi-close-thick
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <slot name="no-data">
          <v-list-item
            class="mt-2 mb-1 justify-space-around text-small"
          >
            <span class="secondary--text text-none">Item tidak ada. Ingin menambahkan?</span>
            <v-btn
              @click="addData(search)"
              x-small
              depressed
              color="grey lighten-2"
              class="text-capitalize mr-2 grey--text text--darken-3"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Tambah Item
            </v-btn>
            <!-- <v-btn
              @click="modalData = true"
              small
              depressed
              color="grey lighten-2"
              class="text-capitalize px-5 grey--text text--darken-3"
            >
              <v-icon x-small class="mr-2">mdi-pencil</v-icon>
              Edit Item
            </v-btn> -->
          </v-list-item>
        </slot>
      </template>
      <template v-slot:prepend-item>
        <template v-if="history">
          <v-list-item class="text-uppercase text-small">
            <v-list-item-content>
              <span class="nexa-black text-subtitle-1 grey--text text--darken-2"
                >HISTORY</span
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn color="red lighten-3" class="py-1" small depressed
                >Hapus Semua</v-btn
              >
            </v-list-item-icon>
          </v-list-item>
          <v-divider
            class="my-1"
            style="border: dashed 1px lightgrey"
          ></v-divider>
        </template>
        <template v-else>
          <template v-if="search">
            <v-list-item class="text-uppercase text-small">
              <v-list-item-icon class="ma-0">
                <v-icon size="large" class="mr-2">mdi-card-search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ search }}
              </v-list-item-content>
              <!-- <v-list-item-icon class="ma-0">
                <v-icon small @click="modalData = true" class="pa-1"
                  >mdi-plus-circle</v-icon
                >
              </v-list-item-icon> -->
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item class="justify-center">
              <v-btn
                @click="modalData = true"
                x-small
                depressed
                fab
                color="grey lighten-2"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </v-list-item>
          </template>
          <v-divider class="my-1"></v-divider>
        </template>
      </template>
      <!-- <template v-slot:append-item>
        <v-divider class="my-1"></v-divider>
        <v-list-item class="justify-center">

        </v-list-item>
      </template> -->
    </v-combobox>
    <v-autocomplete
      v-if="autocomplete2"
      :items="itemSelect"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="inputVal"
      :label="label"
      :class="`${uppercase ? 'text-uppercase' : ''} ${classname}`"
      :color="color"
      :clearable="clearable == undefined ? is_mobile() : clearable"
      :multiple="multiple"
      :small-chips="chips"
      :deletable-chips="chips"
      :autofocus="autofocus"
      clear-icon="mdi-close-thick"
      hide-details="auto"
      dense
      :solo="solo"
      :flat="flat"
      :placeholder="placeholder"
      :background-color="background"
      :append-icon="append"
      v-on="$listeners"
      return-object
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-autocomplete>

    <modal-data
      v-if="autocomplete"
      @getData="getData($event)"
      :key-data="keyData"
      :itemData="items"
      :dialog.sync="modalData"
      @closeDialog="closeDialog"
    />
    <!-- <confirm-dialog
      v-if="autocomplete"
      ref="confirm"
      confirm="Ya"
    >
      Apakah anda yakin menghapus Item ini?
    </confirm-dialog> -->
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Fuse from "fuse.js";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "InputField",
  components: {
    ModalData: () => import("@/components/ModalData"),
    // ConfirmDialog: () => import("@/components/ConfirmDialog")
  },
  inheritAttrs: false,
  props: {
    value: {
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
    classname: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "text",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    appendIcon: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    autocomplete2: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    keyData: {
      type: String,
      default: null,
    },
    select: {
      type: Boolean,
      default: false,
    },
    itemSelect: {
      type: Array,
      default: () => [],
    },
    itemData: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    action: {
      type: String,
      default: null,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: null,
    },
    append: {
      type: String,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    etiket: {
      type: Boolean,
      default: false,
    },
    tipe: {
      type: Boolean,
      default: false,
    },
    triase: {
      type: Boolean,
      default: false,
    },
    prioritas: {
      type: Boolean,
      default: false,
    },
    obat: {
      type: Boolean,
      default: false,
    },
    datePicker: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalData: false,
      search: null,
      poli: [],
      item:
        this.itemData.length
          ? this.itemData
          : this.$store.getters.settings &&
            this.$store.getters.settings[this.keyData]
        ? this.$store.getters.settings[this.keyData]
        : [],
      isLoading: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    inputDate: {
      get() {
        if (!this.value) {
          let tgl = moment(new Date().toISOString().substr(0, 10))
            .format("YYYY-MM-DD")
            .split("-")
          return tgl;
        }
        return this.value.split("-");
      },
      set(val) {
        this.$emit("input", val.join("-"));
      },
    },
    items() {
      return this.settings && this.settings[this.keyData]
        ? this.settings[this.keyData]
        : [];
    },
    ...mapGetters(["settings"]),
  },
  methods: {
    range: function (min, max, desc) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')
      var array = [],
        j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = zeroPad(i, 2);
        j++;
      }
      if (desc) {
        return array.sort(function (a, b) {
          return b - a;
        });
      }
      return array;
    },
    remove(data, item) {
      const index = data.indexOf(item);
      if (index >= 0) data.splice(index, 1);
    },
    getData(val) {
      this.item = val.split("\n");
      this.$snackbar({
        text: "Data berhasil diupdate",
      });
    },
    closeDialog() {
      this.modalData = false;
    },
    addData(v) {
      this.$store.commit("addData", {
        key: this.keyData,
        value: v
      });
      this.item.push(v)
      
      this.$snackbar({
        text: v + " berhasil ditambahkan",
      });
    },
    deleteItem(v) {
      this.$store.commit("deleteData", {
        key: this.keyData,
        value: v
      });
      this.item = this.item.filter(
        (item) => item !== v
      );
      this.$snackbar({
        text: v + " berhasil dihapus",
      });
    },
    querySelections(val) {
      const options = {
        useExtendedSearch: true,
      };

      this.item = this.itemData.length ? this.itemData : this.items;
      const fuse = new Fuse(this.item, options);

      // Search for items that include "Man" and "Old",
      // OR end with "Artist"
      let query = val.split(" ");
      query = query.map((i) => (i ? "'" + i : ""));
      // console.log(query);
      // let item = fuse.search(query.join(" "));
      let item = fuse.search(`'"${val.toLowerCase()}"`);
      let data = [];
      item.forEach(function (item) {
        data.push(item.item);
      });
      this.item = data.sort();
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.item = this.itemData.length ? this.itemData : this.items;
        return;
      }
      val && val !== this.inputVal && this.querySelections(val);
    },
  },
};
</script>
<style scoped>
.datepicker >>> label {
  display: table;
}
</style>
